import { render, staticRenderFns } from "./ColourSwitcher.vue?vue&type=template&id=6b51f2f2&scoped=true&lang=pug&"
import script from "./ColourSwitcher.vue?vue&type=script&lang=js&"
export * from "./ColourSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./ColourSwitcher.vue?vue&type=style&index=0&id=6b51f2f2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b51f2f2",
  null
  
)

export default component.exports