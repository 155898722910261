import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

Vue.use(Vuex);
Vue.config.productionTip = false;

new Vue({
    router,
    render: function (h) {
        return h(App);
    },
}).$mount('#app');
